.message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .message {
    display: inline-block;
    background-color: #f1f0f0;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 80%;
  }
  
  .is-sender {
    align-self: flex-end;
    background-color: #0e9bf7;
    color: #fff;
  }
  
  .message-content {
    margin: 0;
  }
  
  .message-sender {
    font-size: 12px;
    margin: 5px 0 0;
  }
  